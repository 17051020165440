<div class="container">
  <div class="header text-center">
    <img class="logo" src="../assets/logo.png" height="200px" width="auto">
    <p>
      Mark your important events and receive a notification when the count down ends.
    </p>
    <hr>
  </div>
  <div class="content text-center">
      <app-event-form *ngIf="!event" (eventEmit)="saveEvent($event)" width="50%"> </app-event-form>
      <app-timer *ngIf="event" (nullEmit)="saveEvent($event)"></app-timer>
  </div>
  <div class="footer">

  </div>
</div>